import { patchDOMForReact } from '@frontendmentorio/design-system-v2';

import { install } from 'resize-observer';

if (!Number.isInteger) {
  Number.isInteger = function isInteger(value) {
    return (
      typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };
}

if (!Object.fromEntries) {
  Object.fromEntries = function fromEntries(iterable) {
    return [...iterable].reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {});
  };
}

if (!window || !window.ResizeObserver) {
  install();
}

patchDOMForReact();
